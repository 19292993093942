import * as D from '../../def';
export const annotation:D.IAnnotation = {
  // circle: {
  //   pricePoints: [
  //     {
  //       color: 'green',
  //       date: {
  //         year: 2011,
  //         month: 10,
  //         day: 20,
  //       },
  //     },
  //     {
  //       color: 'green',
  //       date: {
  //         year: 2015,
  //         month: 1,
  //         day: 15,
  //       },
  //     },
  //     {
  //       color: 'green',
  //       date: {
  //         year: 2018,
  //         month: 12,
  //         day: 16,
  //       },
  //     },
  //     {
  //       color: 'green',
  //       date: {
  //         year: 2020,
  //         month: 3,
  //         day: 20,
  //       },
  //     },
  //   ],
  //   IndicePoints: [
  //     {
  //       color: 'green',
  //       date: {
  //         year: 2011,
  //         month: 10,
  //         day: 20,
  //       },
  //     },
  //     {
  //       color: 'green',
  //       date: {
  //         year: 2015,
  //         month: 1,
  //         day: 15,
  //       },
  //     },
  //     {
  //       color: 'green',
  //       date: {
  //         year: 2018,
  //         month: 12,
  //         day: 16,
  //       },
  //     },
  //     {
  //       color: 'green',
  //       date: {
  //         year: 2020,
  //         month: 3,
  //         day: 20,
  //       },
  //     },
  //   ],
  // },
};
