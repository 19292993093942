export const introduce = {
  text: `### 使用方法
  + • -25%：说明市场进入怀疑期。
  + • -50%：说明市场进入焦虑期。
  + • -75%：说明市场进入绝望期。
  + 根据历史价格来看，-75%以下往往是周期的最底部。

  ### 指标原理
  + 统计价格距离上一次牛市最高点的回撤幅度。`,
  height: 1360,
};
