export const introduce = {
  text: `### 使用方法
  + 我们默认选择SMA14日均线（您可在工具栏中自定义均线）。
  + • 穿越上边界（黄线上穿红线）：市场情绪过热，价格增长的速度超过活跃地址的增长速度。
  + • 穿越下边界（黄线下穿绿线）：市场情绪过冷，活跃地址的增长速度超过价格的增长速度。
  
  ### 指标原理
  + 该指标由28日价格变化（%）与28日活跃地址数量变化（%）计算得出。
  + 上边界与下边界为「28日活跃地址数量变化」的标准差通道，黄色线为「28日价格变化」。
  + 当黄色线突破上边界说明价格变化（%）超过活跃地址数量变化（%），反之亦然。
  
  ### 创作者
  该指标由 [Philip Swift](https://twitter.com/PositiveCrypto) 创建`,
  height: 1460,
};
