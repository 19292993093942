export const introduce = {
  text: `### 使用方法
  + 我们默认选择SMA7日均线（您可在工具栏中自定义均线）。
  + • 红色区域（85）：代表市场开始贪婪。
  + • 绿色区域（15）：代表市场开始恐惧。
  + 另外请注意该指标从未经历过牛市，没有足够的历史样本证明其有效性。
  
  ### 指标原理
  + • 波动率（25%）
  + 作者认为波动率的上升是市场恐惧的现象。
  + • 市场交易量（25%）
  + 作者认为当市场中的购买量上升时，代表市场开始贪婪。
  + • 社交媒体（15%）
  + 作者主要统计Reddit和Twitter的讨论量，当讨论量增加时说明感兴趣的人数开始增加，代表市场开始贪婪。
  + • 问卷调查（15%）
  + 询问人们对市场的态度，目前关于这个问卷的调研已经暂停。
  + • 比特币的市值占比（10%）
  + 作者认为当比特币的市值占比下降时说明人们开始寻找其他投资机会，可能代表市场开始贪婪。反之市值占比上升，可能代表人们正将比特币作为避险资产，市场开始恐惧。
  + • 搜索引擎搜索量（10%）
  + 作者主要统计Google的搜索量，当「比特币」相关话题搜索量上升时，代表市场开始贪婪。
  
  ### 创作者
  + 该指标来源于 alternative 网站`,
  height: 1730,
};
