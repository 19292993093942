export const headerLinks = [
  {
    title: '首頁',
    link: '/',
  },
  // {
  //   title: '探索',
  //   link: '/discovery',
  // },
  {
    title: '圖表',
    link: '/chart',
  },
  // {
  //   title: '指标目录',
  //   link: '/catalog',
  // },
  // {
  //   title: '联系我们',
  //   link: '/contact',
  // },
  // {
  //   title: '洞见',
  //   link: 'https://weibo.com/p/1005057657665166/wenzhang?from=page_100505_profile&wvr=6&mod=wenzhangmore&articlefeed=1&is_article=1',
  // },
  {
    title: '价格',
    link: '/price',
  },
];
