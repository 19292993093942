export const introduce = {
  text: `### 使用方法
  + 该指标统计了长期持有者持仓变化
  + ·绿色区域：长期持有者增持
  + ·红色区域：长期持有者抛售
  
  ### 指标原理
  + 通过对持有大于六个月的持有者持仓追踪，帮助我们判断长期持有者持仓变化（买入/卖出）`,
  height: 1350,
};
