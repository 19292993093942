export const introduce = {
  text: `### 使用方法
  + ETF 的总资金净流量可以帮助我们判断市场上资金的流入流出情况。
  + • 绿色: 净流入。
  + • 红色：净流出。

  ### 指标原理
  + 该指标显示了在美国主要交易的比特币和以太坊 ETF 的总资金净流量，反映了 ETF 持有量的逐日变化。 其计算方法是，今天的余额数据点与以前可用的本地单位余额数据点之间的差额。 然后，利用纽约时间 16:00 的收盘美元汇率，将每天的本地净流量转换为美元。 从 00:00 到 18:00 UTC 每小时更新一次，以确保及时提供每日报告的余额信息。 与仅在间隔结束后提供更新的标准惯例不同，该指标还在每日 15:00 UTC 时提供每周和每月更新的初步数据。。`,
  height: 1330,
};
