export const introduce = {
  text: `### 使用方法
  + 我们默认选择SMA50日均线（您可在工具栏中自定义均线）。
  + • 红色区域（95%）：当SMA50日流通盈利率超过95%时，说明在过去50日大部分coin都处于盈利状态（根据历史回测往往是顶部）。
  + • 绿色区域（40% 50%）：当SMA50日流通盈利率跌破50%/40%时，说明在过去50日大部分coin处于亏损状态（根据历史回测往往是底部）。

  ### 指标原理
  + 我们统计出所有处于盈利状态中的coin数量（当前价格高于UTXO创建时的价格），并将其处以总流通量，得到流通中的盈利率。`,
  height: 1340,
};
