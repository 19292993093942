export const introduce = {
  text: `### 使用方法
  + 期货资金费率可以帮助我们判断市场上存在杠杆的多少。
  + • 0.05%-0.1%：多头杠杆较多、市场过度贪婪，往往是市场短期顶部。
  + • -0.1%-0%：空头杠杆较多，往往是市场短期底部。

  ### 指标原理
  + 各大主流交易所永续期货合约的平均融资利率（单位：%）。`,
  height: 1330,
};
