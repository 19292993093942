export { default as wrapRootElement } from './src/App';

// export const onClientEntry = () => {
//   document.getElementById('loaderWrapper').style.display = 'block';
//   document.getElementById('webContent').style.hidden = 'true';
// };

// export const onRouteUpdate = () => {
//   document.getElementById('loaderWrapper').style.display = 'none';
//   document.getElementById('webContent').style.hidden = 'false';
// };
