export const introduce = {
  text: `### 使用方法
  + • 绿色区域（-2以下）：当指标数值下降到-2以下时，此时说明稳定币的购买力较强，往往是市场的周期底部。
  
  ### 指标原理
  + 衡量稳定币相对于比特币所产生的购买力，且稳定币供应比率已使用200MA与振荡周期的过滤，通过历史回测发现对价格底部有很好的指示作用。
  + 1）购买力高：如果比特币价格较低，而稳定币资金能够购买流通中BTC供应的更大比例，则会推高比特币价格。
  + 2）购买力低：而随着比特币价格的上涨，稳定币供应能够购买越来越少的BTC，则会降低价格上涨的动力。`,
  height: 1340,
};
