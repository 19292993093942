export const introduce = {
  text: `### 使用方法
  + • 绿色区域：算力收敛，根据历史回测往往对应着市场底部。
  
  ### 指标原理
  + 难度彩虹带由不同时间均线计算出的挖矿难度组成。
  + 例如ma9是取每一个时间点的前九天的挖矿难度平均值计算得出；
  + 通过这个曲线的集合形成的难度带很容易就可以看出挖矿难度的变化率，以及挖矿对比特币价格的影响。
  + 难度带的收缩说明挖矿难度变化率在减小。而比特币挖矿的难度与比特币的价格息息相关，所以当增长的挖矿难度带出现收缩或者相交翻转时，往往就会带来比特币价格的上升，历史上几次底部难度带也都收敛了。
  + 更多介绍见：[关于彩虹带的原理介绍](https://woobull.com/introducing-the-difficulty-ribbon-the-best-times-to-buy-bitcoin/)
  
  ### 创作者
  该指标由 [Willy Woo](https://twitter.com/woonomic) 创建。`,
  height: 1480,
};
